
.wa-page {
    padding-top: 100px;
    font-size: 16px;
    background: linear-gradient(to bottom, #F6F6F6, rgba(255, 255, 255, 0));
    color: #000;
}
.wa-page .slider-wts-section{
    padding: 0;
}

.wa-page .slider-wts-section h1{
    font-size: 40px;
    color: #1D09BA;
    font-weight: 700;
    padding-bottom: 30px;
    font-family: Quicksand-Medium;
}
.wa-page .slider-wts-section h1 strong{
    font-size: 40px;
    color: #000000;
    font-weight: 500;
    font-family: Quicksand-Medium;
}
.wa-page .slider-wts-section p{
    font-size: 18px;
    color: #000000;
    font-weight: 400;
}
.wa-page .slider-wts-section p strong, .wa-page .section-img-sous-text  p strong{
    font-size: 18px;
    color: #000000;
    font-weight: 700;
}
.wa-page .slider-wts-section p span strong{
    color: #1D09BA;
}
.wa-page .separator-slider-wts-section:before {
    background-image: url(/assets/img/logo.svg);
}

.wa-page .section-img-sous-text  h2{
    text-align: center;
}
.wa-page h2{
    font-size: 30px;
    color: #1D09BA;
    font-weight: 700;
    padding-bottom: 25px;
    margin-bottom: 0;
    font-family: Quicksand-Medium;
}
.wa-page h2 strong{
    font-size: 30px;
    color: #000;
    font-weight: 700;
    font-family: Quicksand-Medium;
}
.section-img-sous-text .row{
    position: relative;
    justify-content: center;
}
.section-img-sous-text .row::after{
    content: "";
    background: linear-gradient(to bottom, rgb(33 53 153 / 4%), rgba(255, 255, 255, 0));
    height: 100%;
    position: absolute;
    width: 50%;
    top: 50px;
    border-radius: 80px;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: -1;
}
.wa-page .parg-inscription {
    z-index: 1;
    position: relative;
    gap: 30px;
    font-size: 18px;
    color: #000000;
    padding-top: 30px;
}
.wa-page .parg-inscription p{
    font-size: 18px;
    color: #000000;
}
.wa-page .parg-inscription p strong{
    font-size: 18px;
    color: #000000;
    font-weight: 700;
}
.section-img-left-text, .section-img-right-text{
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 50px;
    font-size: 18px;
    color: #000000;
}
.section-img-left-text h2, .section-img-right-text h2{
    padding-bottom: 15px;
}
.section-img-left-text::after{
    content: "";
    background: linear-gradient(to right, rgb(33 53 153 / 4%), rgba(255, 255, 255, 0));
    height: 240px;
    position: absolute;
    width: 100%;
    border-radius: 80px;
    top: 40px;
    left: 0;
    z-index: -1;
}
.section-img-right-text::after{
    content: "";
    background: linear-gradient(to left, rgb(33 53 153 / 4%), rgba(255, 255, 255, 0));
    height: 240px;
    position: absolute;
    width: 100%;
    border-radius: 80px;
    top: 40px;
    left: 0;
    z-index: -1;
}
.content-text{
    z-index: 1;
    position: relative;
}
.section-functions {
    margin-bottom: 40px;
}
.section-functions .title p{
    font-size: 35px;
    color: #000;
    padding-bottom: 40px;
    font-family: Quicksand-SemiBold;

}
.wa-page h3{
    font-size: 26px;
    color: #1E08BE;
    margin-bottom: 11px;
    font-family: Quicksand-Medium;
}
.wa-page .titre-contact-wts .titre-contact::before{
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='53.369' height='66.004' viewBox='0 0 53.369 66.004'%3e%3cpath id='Combined_Shape' data-name='Combined Shape' d='M42.771%2c58.874A36.866%2c36.866%2c0%2c0%2c0%2c42.708%2c42.7%2c53.164%2c53.164%2c0%2c0%2c0%2c28.993%2c17.249%2c64.264%2c64.264%2c0%2c0%2c0%2c16.877%2c7.255%2c74.37%2c74.37%2c0%2c0%2c0%2c9.123%2c3.012l3.842.466a.709.709%2c0%2c0%2c0%2c.141.045c.507.084%2c1.014.145%2c1.522.195q1.355.169%2c2.74.227l0-.012a1.488%2c1.488%2c0%2c0%2c0%2c1.3-.842.974.974%2c0%2c0%2c0-.836-1.284A32.777%2c32.777%2c0%2c0%2c0%2c13.7%2c1.274L9.73.811Q5.67.334%2c1.588-.077A1.551%2c1.551%2c0%2c0%2c0%2c.029.58.869.869%2c0%2c0%2c0%2c.5%2c1.848q1.008.336%2c2%2c.7a.786.786%2c0%2c0%2c0-.011.116%2c16.571%2c16.571%2c0%2c0%2c0%2c.481%2c4.463A13.449%2c13.449%2c0%2c0%2c0%2c3.76%2c9.333a3.019%2c3.019%2c0%2c0%2c0%2c1.19%2c1.636%2c1.032%2c1.032%2c0%2c0%2c0%2c.739.013.841.841%2c0%2c0%2c0%2c.515-.456%2c3.214%2c3.214%2c0%2c0%2c0-.371-1.851c-.226-.654-.411-1.321-.6-1.987a24%2c24%2c0%2c0%2c1-.7-3.356A62.978%2c62.978%2c0%2c0%2c1%2c16.758%2c9.871%2c58.7%2c58.7%2c0%2c0%2c1%2c36.325%2c31.66%2c49.4%2c49.4%2c0%2c0%2c1%2c40.81%2c44.869%2c64.309%2c64.309%2c0%2c0%2c1%2c41.676%2c58.8h0c-.008.274.3.426.6.415A.49.49%2c0%2c0%2c0%2c42.771%2c58.874Z' transform='matrix(-0.985%2c -0.174%2c 0.174%2c -0.985%2c 42.991%2c 65.891)' fill='%231e08be'/%3e%3c/svg%3e");
}
.wa-page p{
    font-size: 18px;
    color: #000;
}
.content-functions{
   /* background-image: url(/assets/img/functions.png);*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.icone-function{
    height: 162px;
    width: 162px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 32px;
    box-shadow: 0px 3px 44px rgba(0, 0, 0, 0.06);
    margin-top: 40px;
}
.wa-page .box-downsection-img-left-text {
    margin-top: 80px;
}
.wa-page .titre-contact-wts .titre-contact {
    font-size: 30px;
    color: #1D09BA;
    padding-bottom: 25px;
    margin-bottom: 0;
}
.wa-page .btn-condition {
    background-color: #1D09BA !important;
}
@media only screen and (max-width: 991.5px) {
    .text-slider-wa{
        padding-top: 100px;
    }
    .section-img-left-text::after {
        height: 90%;
        top: 0;
    }
    .section-img-right-text::after{
        height: 100%;
        top: 0;
    }
    .wa-page .separator-slider-wts-section{
        margin-top: 0px;
    }
    .wa-page .box-downsection-img-left-text{
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .section-img-left-text, .section-img-right-text{
        margin-bottom: 20px;
    }
}